










import Vue from 'vue'

import VSelect from '../../BaseComponents/VSelect/VSelect.vue'
import { mapMutations } from 'vuex'

export default Vue.extend({
  name: 'InterestsExploreSearchFiltersWordsItem',
  data () {
    return {
      value: 'do nothing'
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    word: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  components: { VSelect },
  methods: {
    ...mapMutations('interests', ['changeTypeFilterWord']),
    changeOption (acc) {
      if (acc !== 'delete') {
        this.value = acc
      }
      this.changeTypeFilterWord({ id: this.id, type: acc })
    }
  }
})
