





































import Vue from 'vue'

// import AdAccountDropdown from '../components/AdAccountDropdown.vue'
import InterestsExploreSearchInput from '../components/interests/InterestsExploreSearchInput.vue'
import InterestsExploreSearchFilters from '../components/interests/InterestsExploreSearchFilters.vue'
import InterestsExploreSearchResults from '../components/interests/InterestsExploreSearchResults.vue'
import BasePagePreloader from '../components/BasePagePreloader.vue'
import { mapActions, mapState } from 'vuex'
export default Vue.extend({
  name: 'InterestsExploreSearch',
  components: {
    // AdAccountDropdown,
    InterestsExploreSearchInput,
    InterestsExploreSearchFilters,
    InterestsExploreSearchResults,
    BasePagePreloader
  },
  data () {
    return {
      loading: true,
      selectedAdAccount: null,
      value: [10, 20],
      min: 10,
      max: 100
    }
  },
  computed: {
    ...mapState('interests', [
      'searchResults',
      'suggestionResults',
      'interestsCollection',
      'loadingSearch'])
  },
  methods: {
    ...mapActions('interests', [
      'searchInterests',
      'getLocalesList'
    ]),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('adService', ['setService']),
    search (acc) {
      this.searchInterests(acc)
    }
  },
  mounted () {
    this.setService('facebook')
    this.loadAccounts({ search: { target: 'ads_management' } })
      .then(() => {
        this.getLocalesList()
        this.selectedAdAccount = this.$store.state.adAccounts.data[0]
        this.loading = false
      })
  }
})
