


































































































































































import Vue from 'vue'

import InterestsExploreSearchResultsItem from './InterestsExploreSearchResultsItem.vue'
import Pagination from '../BaseComponents/BasePagination/BasePagination.vue'
import VSelect from '../../components/BaseComponents/VSelect/VSelect.vue'
import { mapMutations, mapState } from 'vuex'
export default Vue.extend({
  name: 'InterestsExploreSearchResults',
  components: { VSelect, InterestsExploreSearchResultsItem, Pagination },
  data () {
    return {
      activePage: 1,
      activeSuggestionPage: 1,
      start: 0,
      end: 10,
      per_page: 10,
      sortSuggestDirectionAudienceSize: 'asc',
      sortDirectionAudienceSize: 'asc',
      sortDirectionAudienceName: 'asc',
      sortDirectionType: 'asc'
    }
  },
  props: {
    quantity: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapState('interests', ['interestsCollection', 'searchResults', 'suggestionResults', 'initialSearchResults']),
    interestsCollectionIds () {
      const arr = []
      this.interestsCollection.forEach(item => {
        arr.push(item.audience_id)
      })
      return arr
    },
    suggestionResultsIds () {
      const arr = []
      this.suggestionResults.forEach(item => {
        arr.push(item.id)
      })
      return arr
    },
    searchResultsIds () {
      const arr = []
      this.searchResults.forEach(item => {
        arr.push(item.id)
      })
      return arr
    }
  },
  methods: {
    ...mapMutations('interests', ['addToInterestCollection', 'deleteFromInterestCollection']),
    allSuggestion () {
      this.suggestionResults.forEach(item => {
        this.addToInterestCollection({ suggestionId: item.id, projectId: this.$route.params.id })
      })
    },
    allSearchResults () {
      this.searchResults.forEach(item => {
        this.addToInterestCollection({ interestId: item.id, projectId: this.$route.params.id })
      })
    },
    deleteAllSuggestion () {
      this.suggestionResults.forEach(item => {
        this.deleteFromInterestCollection(item.id)
      })
    },
    deleteAllSearchResults () {
      this.searchResults.forEach(item => {
        this.deleteFromInterestCollection(item.id)
      })
    },
    sortByField (field, directionField, type = String) {
      const direction = this[directionField] === 'desc'
      this[directionField] = direction ? 'asc' : 'desc'
      const x = direction ? -1 : 1
      const y = direction ? 1 : -1
      this.searchResults.sort((a, b) => {
        if (type(a[field]) > type(b[field])) return x
        if (type(a[field]) < type(b[field])) return y
        return 0
      })
    },
    // sortByAudience () {
    //   const direction = this.sortDirectionAudienceSize === 'desc'
    //   this.sortDirectionAudienceSize = direction ? 'asc' : 'desc'
    //   const x = direction ? -1 : 1
    //   const y = direction ? 1 : -1
    //   this.searchResults.sort((a, b) => {
    //     if (Number(a.audience_size) > Number(b.audience_size)) return x
    //     if (Number(a.audience_size) < Number(b.audience_size)) return y
    //     return 0
    //   })
    // },
    sortSuggestByAudience () {
      const direction = this.sortSuggestDirectionAudienceSize === 'desc'
      this.sortSuggestDirectionAudienceSize = direction ? 'asc' : 'desc'
      const x = direction ? -1 : 1
      const y = direction ? 1 : -1
      this.suggestionResults.sort((a, b) => {
        if (Number(a.audience_size) > Number(b.audience_size)) return x
        if (Number(a.audience_size) < Number(b.audience_size)) return y
        return 0
      })
    },
    paginating (arr, a, b) {
      return arr.slice(a, b)
    },
    changePage (page) {
      this.activePage = page
      this.start = (page - 1) * this.per_page
      this.end = (page - 1) * this.per_page + this.per_page
    },
    changePerPage (acc) {
      this.per_page = acc
      this.changePage(this.activePage)
    }
  }
})
