









import Vue from 'vue'

export default Vue.extend({
  name: 'InterestsExploreSearchInputTag',
  props: {
    index: {
      type: Number
    },
    tag: {
      type: String
    }
  },
  components: {},
  methods: {
    deleteTag () {
      this.$emit('deleteTag', { index: this.index, tag: this.tag })
    }
  }
})
