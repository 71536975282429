var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.initialSearchResults.length > 0)?_c('Card',[_c('div',{staticClass:"search-results"},[_c('div',{staticClass:"search-results-quantity"},[(_vm.searchResults)?_c('p',[_vm._v(_vm._s(_vm.initialSearchResults.length)),_c('span',[_vm._v(" results found")])]):_vm._e()]),_c('div',{staticClass:"search-results-quantity filtered"},[(_vm.searchResults)?_c('p',[_vm._v(_vm._s(_vm.searchResults.length)),_c('span',[_vm._v(" result filtered")])]):_vm._e()]),_c('table',{staticClass:"simple-table simple-table-stripe mt-3 table-results"},[_c('thead',[_c('tr',{staticClass:"rowTable spaceAround"},[(_vm.searchResults.length)?_c('td',{staticClass:"full_length",staticStyle:{"width":"50px"}},[(_vm.searchResultsIds.every(function (e) { return _vm.interestsCollectionIds.includes(e); }))?_c('button',{staticClass:"standard-btn standard-btn-danger",on:{"click":function($event){return _vm.deleteAllSearchResults()}}},[_vm._v(" Delete all ")]):_c('button',{staticClass:"standard-btn standard-btn-primary",on:{"click":function($event){return _vm.allSearchResults()}}},[_vm._v(" All ")])]):_vm._e(),_c('td',{staticClass:"full_length"},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.searchResults.length)+" Results found")]),_c('img',{staticClass:"sort-button ms-1",staticStyle:{"fill":"red!important"},attrs:{"src":_vm.sortDirectionAudienceName === 'asc'
                        ? require('../../assets/icons/sort-icon-desc.svg')
                        : require('../../assets/icons/sort-icon-asc.svg'),"alt":""},on:{"click":function($event){return _vm.sortByField('name', 'sortDirectionAudienceName')}}})])]),_c('td',{staticClass:"td_type_audience"},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v("Type")]),_c('img',{staticClass:"sort-button ms-1",staticStyle:{"fill":"red!important"},attrs:{"src":_vm.sortDirectionType === 'asc'
                        ? require('../../assets/icons/sort-icon-desc.svg')
                        : require('../../assets/icons/sort-icon-asc.svg'),"alt":""},on:{"click":function($event){return _vm.sortByField('type', 'sortDirectionType')}}})])]),_c('td',[_c('div',{staticClass:"d-flex td_type_audience"},[_c('span',[_vm._v("Audience")]),_c('img',{staticClass:"sort-button ms-1",staticStyle:{"fill":"red!important"},attrs:{"src":_vm.sortDirectionAudienceSize === 'asc'
                      ? require('../../assets/icons/sort-icon-desc.svg')
                      : require('../../assets/icons/sort-icon-asc.svg'),"alt":""},on:{"click":function($event){return _vm.sortByField('audience_size', 'sortDirectionAudienceSize', Number)}}})])]),_c('td',{staticClass:"invisible"},[_vm._v("Search")])])]),_c('tbody',[_vm._l((_vm.paginating(_vm.searchResults, _vm.start, _vm.end)),function(interest,index){return _c('InterestsExploreSearchResultsItem',{key:index,attrs:{"state":!!_vm.interestsCollection.filter(function (v) { return v.audience_id === interest.id; }).length ? 'added' : 'unadded',"name":interest.name,"size":Number(interest.audience_size),"type":interest.type ? interest.type : 'interest'},on:{"add":function($event){return _vm.addToInterestCollection({ interestId: interest.id, projectId: _vm.$route.params.id })},"delete":function($event){return _vm.deleteFromInterestCollection(interest.id)}}})}),(_vm.paginating(_vm.searchResults, _vm.start, _vm.end).length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v("Search results")])]):_vm._e()],2)]),(_vm.searchResults.length > 0)?_c('div',{staticClass:"search-result-pagination searchAdoptive d-flex justify-content-between mt-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"me-2"},[_vm._v("Show")]),_c('VSelect',{staticClass:"VSelect",attrs:{"max-width":64,"min-width":64,"append-to-body":true,"options":[5, 10, 15],"value":_vm.per_page},on:{"input":function($event){return _vm.changePerPage($event)}}})],1),(_vm.searchResults.length > _vm.per_page)?_c('Pagination',{staticClass:"PaginationWrapper",attrs:{"page-count":Math.ceil(_vm.searchResults.length/_vm.per_page),"page-range":3 + (Math.ceil(_vm.searchResults.length/_vm.per_page) > 7 && _vm.activePage <= 3 && 2) +
                           (Math.ceil(_vm.searchResults.length/_vm.per_page) > 7 && _vm.activePage > Math.ceil(_vm.searchResults.length/_vm.per_page) - 3 && 2),"margin-pages":1},on:{"changePage":_vm.activePage,"getData":function($event){return _vm.changePage($event)}}}):_vm._e()],1):_vm._e()])]):_vm._e(),_c('Card',[_c('div',{staticClass:"search-results-suggestions"},[_c('div',{staticClass:"search-results-quantity green"},[_c('p',[_vm._v(_vm._s(_vm.suggestionResults.length)+" "),_c('span',[_vm._v(" suggestions found")])])]),_c('table',{staticClass:"simple-table simple-table-stripe mt-3 suggestions-table"},[_c('thead',[_c('tr',[(_vm.suggestionResults.length)?_c('td',{staticStyle:{"width":"50px"}},[(_vm.suggestionResultsIds.every(function (e) { return _vm.interestsCollectionIds.includes(e); }))?_c('button',{staticClass:"standard-btn standard-btn-danger",on:{"click":function($event){return _vm.deleteAllSuggestion()}}},[_vm._v(" Delete all ")]):_c('button',{staticClass:"standard-btn standard-btn-primary",on:{"click":function($event){return _vm.allSuggestion()}}},[_vm._v(" All ")])]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.suggestionResults.length)+" Results found")]),_c('td',[_vm._v("Type")]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v("Audience")]),_c('img',{staticClass:"sort-button ms-1",staticStyle:{"fill":"red!important"},attrs:{"src":_vm.sortSuggestDirectionAudienceSize === 'asc'
                      ? require('../../assets/icons/sort-icon-desc.svg')
                      : require('../../assets/icons/sort-icon-asc.svg'),"alt":""},on:{"click":function($event){return _vm.sortSuggestByAudience()}}})])]),_c('td',[_vm._v("Search")])])]),_c('tbody',[_vm._l((_vm.suggestionResults),function(suggestion,index){return _c('InterestsExploreSearchResultsItem',{key:index,attrs:{"name":suggestion.name,"size":Number(suggestion.audience_size),"type":suggestion.type ? suggestion.type : 'interest',"state":!!_vm.interestsCollection.filter(function (v) { return v.audience_id === suggestion.id; }).length ? 'added' : 'unadded'},on:{"add":function($event){return _vm.addToInterestCollection({ suggestionId: suggestion.id, projectId: _vm.$route.params.id })},"delete":function($event){return _vm.deleteFromInterestCollection(suggestion.id)}}})}),(_vm.suggestionResults.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v("Search results for suggestions will be here")])]):_vm._e()],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }