










































import Vue from 'vue'

import VSelect from '../../BaseComponents/VSelect/VSelect.vue'
import VueSlider from 'vue-slider-component'
import { mapMutations } from 'vuex'
export default Vue.extend({
  name: 'InterestsExploreSearchAudienceSize',
  data () {
    return {
      range: ['All', '25k to 50k', '50k to 100k', '100k to 200k', '200k & Above'],
      rangePresetting: 'All',
      minValue: 0,
      maxValue: 100000000
    }
  },
  components: { VSelect, VueSlider },
  methods: {
    ...mapMutations('interests', ['setAudienceSizes', 'setMinMaxAudienceSizes']),
    setValue () {
      this.setAudienceSizes(this.$store.state.interests.rangeAudienceSizes)
    },
    chooseRangeSetting (acc) {
      this.rangePresetting = acc
      switch (acc) {
        case 'All':
          this.setMinMaxAudienceSizes([Math.min.apply(null, this.$store.state.interests.audienceSize), Math.max.apply(null, this.$store.state.interests.audienceSize)])
          this.setAudienceSizes([Math.min.apply(null, this.$store.state.interests.audienceSize), Math.max.apply(null, this.$store.state.interests.audienceSize)])
          break
        case '25k to 50k':
          this.setMinMaxAudienceSizes([25000, 50000])
          this.setAudienceSizes([25000, 50000])
          break
        case '50k to 100k':
          this.setMinMaxAudienceSizes([50000, 100000])
          this.setAudienceSizes([50000, 100000])
          break
        case '100k to 200k':
          this.setMinMaxAudienceSizes([100000, 200000])
          this.setAudienceSizes([100000, 200000])
          break
        case '200k & Above':
          this.setMinMaxAudienceSizes([200000, Math.max.apply(null, this.$store.state.interests.audienceSize)])
          this.setAudienceSizes([200000, Math.max.apply(null, this.$store.state.interests.audienceSize)])
          break
        default:
         //
      }
    }
  },
  updated () {
    this.minValue = this.$store.state.interests.minAudienceSize
    this.maxValue = this.$store.state.interests.maxAudienceSize
  }
})
