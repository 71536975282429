




















import Vue from 'vue'

import InterestsExploreSearchFiltersWordsItem from './InterestsExploreSearchFiltersWordsItem.vue'
import InterestsExploreSearchFiltersWordsAdd from './InterestsExploreSearchFiltersWordsAdd.vue'

import { mapState } from 'vuex'
export default Vue.extend({
  name: 'InterestsExploreSearchFiltersWords',
  components: { InterestsExploreSearchFiltersWordsItem, InterestsExploreSearchFiltersWordsAdd },
  data () {
    return {
      showAddWordForm: false
    }
  },
  computed: {
    ...mapState('interests', ['filterWords'])
  },
  methods: {
    addWord () {
      this.showAddWordForm = true
    }
  }
})
