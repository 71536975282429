

























import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'InterestsExploreSearchFiltersWordsAdd',
  computed: {
    ...mapState('interests', ['addWordValue', 'addWordError'])
  },
  methods: {
    ...mapMutations('interests', ['addFilterWord', 'setStateProp']),
    cancelAdding () {
      this.$emit('cancelAdding')
    },
    change (event) {
      this.setStateProp({ prop: 'addWordValue', value: event.target.value })
      this.setStateProp({ prop: 'addWordError', value: '' })
    }
  }
})
