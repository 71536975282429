




















import Vue from 'vue'

import BaseCheckbox from '../../../components/BaseCheckbox.vue'
import { mapMutations, mapState } from 'vuex'
export default Vue.extend({
  name: 'InterestsExploreSearchTargetingTypes',
  data () {
    return {
      openTargetingTypeBody: false
    }
  },
  components: { BaseCheckbox },
  computed: {
    ...mapState('interests', ['targetingTypes', 'selectedTargetingTypes'])
  },
  methods: {
    ...mapMutations('interests', ['addToSelectedTargetingType']),
    test () {
      this.openTargetingTypeBody = !this.openTargetingTypeBody
    },
    title (type) {
      return String(type.data + ' (' + type.sum + ')')
    }
  }
})
