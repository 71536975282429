


















































import Vue from 'vue'

import InterestsExploreSearchInputTag from './InterestsExploreSearchInputTag.vue'
import VSelect from '../../components/BaseComponents/VSelect/VSelect.vue'
import { mapMutations, mapGetters, mapState } from 'vuex'
import CountryFlag from 'vue-country-flag'
export default Vue.extend({
  name: 'InterestsExploreSearchInput',
  components: { InterestsExploreSearchInputTag, VSelect, CountryFlag },
  data () {
    return {
      searchWord: '',
      selected: {
        name: 'English(US)',
        value: 'en_US'
      }
    }
  },
  methods: {
    ...mapMutations('interests', [
      'addSearchWord', 'deleteSearchWord', 'setStateProp'
    ]),
    addTag () {
      if (this.searchWord.length > 0) {
        this.addSearchWord(this.searchWord)
        this.searchWord = ''
      }
    },
    deleteTag (acc) {
      this.deleteSearchWord(acc.index)
    },
    initiateSearch () {
      if (this.searchWord.length !== 0) {
        this.searchWords.push(this.searchWord)
        this.searchWord = ''
      }
      this.$emit('search', this.searchWords)
    },
    selecting (acc) {
      this.selected = acc
      this.setStateProp({ prop: 'selectLocale', value: acc.value })
    }
  },
  computed: {
    ...mapState('interests', ['searchWords'])
  }
})
