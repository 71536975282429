














import Vue from 'vue'
import InterestsExploreSearchFiltersTargetingTypes from './filters/InterestsExploreSearchFiltersTargetingTypes.vue'
import InterestsExploreSearchFiltersAudienceSize from './filters/InterestsExploreSearchFiltersAudienceSize.vue'
import InterestsExploreSearchFiltersWords from './filters/InterestsExploreSearchFiltersWords.vue'
import { mapMutations } from 'vuex'

export default Vue.extend({
  name: 'InterestsExploreSearchFilters',
  components: { InterestsExploreSearchFiltersTargetingTypes, InterestsExploreSearchFiltersAudienceSize, InterestsExploreSearchFiltersWords },
  methods: {
    ...mapMutations('interests', ['applyFilters'])
  }
})
