


















import Vue from 'vue'
export default Vue.extend({
  name: 'InterestsExploreSearchResultsItem',
  props: {
    state: {
      type: String,
      validator: (value) => {
        return ['added', 'unadded'].indexOf(value) !== -1
      },
      default: 'unadded'
    },
    name: {
      type: String
    },
    size: {
      type: Number
    },
    type: {
      type: String
    }
  },
  methods: {
    changeState () {
      this.state === 'unadded'
        ? this.$emit('add')
        : this.$emit('delete')
    }
  }
})
